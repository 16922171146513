// padding Top
.t-pt-5 {
  padding-top: 5px;
}
.t-pt-10 {
  padding-top: 10px;
}
.t-pt-15 {
  padding-top: 15px;
}
.t-pt-20 {
  padding-top: 20px;
}
.t-pt-25 {
  padding-top: 25px;
}
.t-pt-30 {
  padding-top: 30px;
}
.t-pt-35 {
  padding-top: 35px;
}
.t-pt-40 {
  padding-top: 40px;
}
.t-pt-45 {
  padding-top: 45px;
}
.t-pt-50 {
  padding-top: 50px;
}
.t-pt-55 {
  padding-top: 55px;
}
.t-pt-60 {
  padding-top: 60px;
}
.t-pt-65 {
  padding-top: 65px;
}
.t-pt-70 {
  padding-top: 70px;
}
.t-pt-75 {
  padding-top: 75px;
}
.t-pt-80 {
  padding-top: 80px;
}
.t-pt-85 {
  padding-top: 85px;
}
.t-pt-90 {
  padding-top: 90px;
}
.t-pt-95 {
  padding-top: 95px;
}
.t-pt-100 {
  padding-top: 100px;
}
.t-pt-105 {
  padding-top: 105px;
}
.t-pt-110 {
  padding-top: 110px;
}
.t-pt-115 {
  padding-top: 115px;
}
.t-pt-120 {
  padding-top: 120px;
}

// padding Bottom
.t-pb-5 {
  padding-bottom: 5px;
}
.t-pb-10 {
  padding-bottom: 10px;
}
.t-pb-15 {
  padding-bottom: 15px;
}
.t-pb-20 {
  padding-bottom: 20px;
}
.t-pb-25 {
  padding-bottom: 25px;
}
.t-pb-30 {
  padding-bottom: 30px;
}
.t-pb-35 {
  padding-bottom: 35px;
}
.t-pb-40 {
  padding-bottom: 40px;
}
.t-pb-45 {
  padding-bottom: 45px;
}
.t-pb-50 {
  padding-bottom: 50px;
}
.t-pb-55 {
  padding-bottom: 55px;
}
.t-pb-60 {
  padding-bottom: 60px;
}
.t-pb-65 {
  padding-bottom: 65px;
}
.t-pb-70 {
  padding-bottom: 70px;
}
.t-pb-75 {
  padding-bottom: 75px;
}
.t-pb-80 {
  padding-bottom: 80px;
}
.t-pb-85 {
  padding-bottom: 85px;
}
.t-pb-90 {
  padding-bottom: 90px;
}
.t-pb-95 {
  padding-bottom: 95px;
}
.t-pb-100 {
  padding-bottom: 100px;
}
.t-pb-105 {
  padding-bottom: 105px;
}
.t-pb-110 {
  padding-bottom: 110px;
}
.t-pb-115 {
  padding-bottom: 115px;
}
.t-pb-120 {
  padding-bottom: 120px;
}

// padding Right
.t-pr-5 {
  padding-right: 5px;
}
.t-pr-10 {
  padding-right: 10px;
}
.t-pr-15 {
  padding-right: 15px;
}
.t-pr-20 {
  padding-right: 20px;
}
.t-pr-25 {
  padding-right: 25px;
}
.t-pr-30 {
  padding-right: 30px;
}
.t-pr-35 {
  padding-right: 35px;
}
.t-pr-40 {
  padding-right: 40px;
}
.t-pr-45 {
  padding-right: 45px;
}
.t-pr-50 {
  padding-right: 50px;
}
.t-pr-55 {
  padding-right: 55px;
}
.t-pr-60 {
  padding-right: 60px;
}
.t-pr-65 {
  padding-right: 65px;
}
.t-pr-70 {
  padding-right: 70px;
}
.t-pr-75 {
  padding-right: 75px;
}
.t-pr-80 {
  padding-right: 80px;
}
.t-pr-85 {
  padding-right: 85px;
}
.t-pr-90 {
  padding-right: 90px;
}
.t-pr-95 {
  padding-right: 95px;
}
.t-pr-100 {
  padding-right: 100px;
}
.t-pr-105 {
  padding-right: 105px;
}
.t-pr-110 {
  padding-right: 110px;
}
.t-pr-115 {
  padding-right: 115px;
}
.t-pr-120 {
  padding-right: 120px;
}

// padding Left
.t-pl-5 {
  padding-left: 5px;
}
.t-pl-10 {
  padding-left: 10px;
}
.t-pl-15 {
  padding-left: 15px;
}
.t-pl-20 {
  padding-left: 20px;
}
.t-pl-25 {
  padding-left: 25px;
}
.t-pl-30 {
  padding-left: 30px;
}
.t-pl-35 {
  padding-left: 35px;
}
.t-pl-40 {
  padding-left: 40px;
}
.t-pl-45 {
  padding-left: 45px;
}
.t-pl-50 {
  padding-left: 50px;
}
.t-pl-55 {
  padding-left: 55px;
}
.t-pl-60 {
  padding-left: 60px;
}
.t-pl-65 {
  padding-left: 65px;
}
.t-pl-70 {
  padding-left: 70px;
}
.t-pl-75 {
  padding-left: 75px;
}
.t-pl-80 {
  padding-left: 80px;
}
.t-pl-85 {
  padding-left: 85px;
}
.t-pl-90 {
  padding-left: 90px;
}
.t-pl-95 {
  padding-left: 95px;
}
.t-pl-100 {
  padding-left: 100px;
}
.t-pl-105 {
  padding-left: 105px;
}
.t-pl-110 {
  padding-left: 110px;
}
.t-pl-115 {
  padding-left: 115px;
}
.t-pl-120 {
  padding-left: 120px;
}
