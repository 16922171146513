.widget-category {
    &__item {
        border-bottom: 1px dashed rgb(var(--base));
        &:last-child {
            border-bottom: none;
            .vh-widget-category__link {
                padding-bottom: 0;
            }
        }
    }
    &__link {
        display: inline-block;
        padding-bottom: 1rem;
        color: hsl(var(--text));
        transition: all .3s ease;
        &:hover {
            color: rgb(var(--base));
        }
    }
}