/*---------------------------------------
    Theme Name: Viserzoo - Zoo Template
    Description: Zoo HTML Template
    Author Name: Viserlab
    Author URI:  https://themeforest.net/user/viserlab
    Theme URI: 
    Version: 1.0
-----------------------------------------
    Table of contents
-----------------------------------------
    1 Base Style
        1. Global
    2 Modules Style
        2.1 Hero
        2.2 Header
        2.3 Logo
        2.4 List
        2.5 Nav
        2.6 Primary Nav
        2.7 Nav Toggler
        2.8 Nice Select
        2.9 Form Control
        2.10 Primary Submenu Toggler
        2.11 Preloader
        2.12 Back To Top
        2.13 Button Style
        2.14 Section 
        2.15 Icon
        2.16 Search Popup
        2.17 Widget
        2.18 Widget Category
        2.19 User
        2.20 Banner
        2.21 Table
        2.22 Accordion
        2.23 Badge
        2.24 Form Select
        2.25 Custom Check
        2.26 Breadcrumbs
        2.27 Custom Pagination
        2.28 Custom Card
        2.29 Custom Modal
        2.30 Fixed Header 
        2.31 User Group List
        2.32 Newsletter
        2.33 Input Group
        2.34 Hero Slider
        2.35 Hero Nav
        2.36 Animal List
        2.37 Welcom Card
        2.38 Gallery Card
        2.39 Counter Card
        2.40 Membership Card
        2.41 Feedback Card
        2.42 Feedback Slider
        2.43 Event Card
        2.44 Expert Card
        2.45 Blog Post
        2.46 Client Card
        2.47 Subscribe Section
        2.48 Animal List Alternative Style
        2.49 Animal Category
        2.50 Info Image
        2.51 About Card
        2.52 About Image
        2.53 Counter List
        2.54 Gallery ALT Style
        2.55 Member Card
        2.56 Testimonial Card
        2.57 Testimonial Slider
        2.58 Team Card
        2.59 Event Image
        2.60 Upcoming Card
        2.61 Upcoming Slider
        2.62 Animal Card
        2.63 Client Slider
        2.64 Animal Details Slider
        2.65 Event Counter
        2.66 Fullsection Image
        2.67 Zoo Box
        2.68 Service Card
        2.69 Search Box
        2.70 Classic Blog
        2.71 Blockquote
        2.72 Divider
        2.73 Login Form
        2.74 Map View
        2.75 Header Top
    3 Theme Style
        3.1 Color
        3.2 Background
        3.3 Padding
        3.4 Margin
        3.5 Utility Classes
        3.6 Animation
    4. Layouts Style
        4.1 Footer
        4.2 Welcome Section
        4.3 Info Section
        4.4 Fact Section
        4.5 Membership Section
        4.6 Feedback Section
        4.7 Expert Section
        4.8 Exhibition Section
        4.9 FAQ Section
        4.10 Testimonial Section
        4.11 Event Section
        4.12 Footer Section
        4.13 Login Section
        4.14 Signup Section

---------------------------------------*/
/*---------------------------------------
    0.1 Base Style
-----------------------------------------*/
@import "1-base/1-base";

/*---------------------------------------
    0.2 Modules Style
-----------------------------------------*/
@import "2-module/2-module";

/*---------------------------------------
    0.3 Theme Style
-----------------------------------------*/
@import "3-theme/3-theme";

/*---------------------------------------
    0.4 Layouts Style
-----------------------------------------*/
@import "4-layouts/4-layouts";
