.membership-section {
  position: relative;
  isolation: isolate;
  &::before {
    @include xxl-screen {
      content: url(../images/membership-img-1.png);
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
  &::after {
    @include xxl-screen {
      content: url(../images/membership-img-2.png);
      position: absolute;
      right: 0;
      bottom: -7px;
      z-index: -1;
    }
  }
}
