.about-image {
  display: grid;
  gap: 1rem;
  @include md-screen {
    position: relative;
    isolation: isolate;
  }
  @include xlii-screen {
      padding-left: 50px;
  }
  &__text {
    display: block;
    letter-spacing: 0.04em;
  }
  &__content {
    padding: 20px;
    background: rgb(var(--base));
    position: relative;
    isolation: isolate;
    @include md-screen {
      position: absolute;
      top: 15px;
      right: 15px;
      aspect-ratio: 1;
      z-index: 1;
    }
    @include lg-screen {
      top: 30px;
      right: 0;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 10px;
      border: 1px dashed hsl(var(--white));
    }
  }
  &__one {
    @include lg-screen {
      width: 400px;
      height: 400px;
      position: absolute;
      top: 0;
      right: 30px;
    }
    &-is {
      width: 100%;
      height: 100%;
      @include lg-screen {
        object-fit: cover;
        aspect-ratio: 1;
      }
    }
  }
  &__two {
    @include lg-screen {
      width: 280px;
      height: 280px;
      position: relative;
      top: 230px;
      left: 0;
      &::after {
        content: "";
        width: 140px;
        height: 10px;
        position: absolute;
        left: 0;
        top: -30px;
        background: rgb(var(--base));
      }
    }
    &-is {
      width: 100%;
      height: 100%;
      @include lg-screen {
        object-fit: cover;
        aspect-ratio: 1;
      }
    }
  }
}
