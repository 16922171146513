.text--primary {
  color: hsl(var(--primary));
}
.text--secondary {
  color: hsl(var(--secondary));
}
.text--success {
  color: hsl(var(--success));
}
.text--danger {
  color: hsl(var(--danger));
}
.text--warning {
  color: hsl(var(--warning));
}
.text--info {
  color: hsl(var(--info));
}
.text--dark {
  color: hsl(var(--dark));
}
.text--white {
  color: hsl(var(--white));
}
.text-clr {
  color: hsl(var(--text));
}
.heading-clr {
  color: hsl(var(--heading));
}
.text--base {
  color: rgb(var(--base));
}
.text--accent {
  color: hsl(var(--accent));
}
.text--accent-400 {
  color: hsl(var(--accent-400));
}