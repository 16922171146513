.nav-container {
    position: relative;
}
.navs {
    min-width: 200px;
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    border-radius: 5px;
    background-color: hsl(var(--white));
    transition: all .4s ease;
    visibility: hidden;
    opacity: 0;
    z-index: -9999;
    box-shadow: 0 5px 15px hsl(var(--black)/.3);
    @include md-screen {
        min-width: 250px;
    }
    @include lg-screen {
        display: flex;
        align-items: center;
        min-width: auto;
        position: relative;
        top: auto;
        right: auto;
        padding-left: 2rem;
        visibility: visible;
        opacity: 1;
        z-index: 9998;
        background: transparent;
        box-shadow: none;
        &::after {
            display: none;
        }
    }
    &::after {
        content: '';
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid hsl(var(--light));
        position: absolute;
        top: -20px;
        right: 10px;
    }
    &--toggle{
        padding: 3px 5px;
        border-radius: 2px;
        border: 1px solid hsl(var(--light));
        font-size: 24px;
        color: hsl(var(--white));
        line-height: 1;
        transition: all .3s ease;
        &:hover {
            background-color: hsl(var(--white));
            color: hsl(var(--dark));
        }
    }
}