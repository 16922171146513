.gallery-card {
    position: relative;
    isolation: isolate;
    border-radius: 5px;
    overflow: hidden;
    &:hover {
        .gallery-card__overlay {
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }
    }
    &__img {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
    }
    &__overlay {
        position: absolute;
        inset: 0;
        display: grid;
        place-items: center;
        background: hsl(var(--dark)/.6);
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
    }
    &__link {
        display: grid;
        place-items: center;
        width: 65px;
        height: 65px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: rgb(var(--base));
        color: hsl(var(--white));
        font-size: 24px;
        &:hover {
            color: hsl(var(--white)/.8);
            outline: 10px solid hsl(var(--white)/.5);
        }
    }
}