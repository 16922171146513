.custom--check {
    border-radius: 1px !important;
    border: 1px solid hsl(var(--border));
    background: hsl(var(--light));
    &:checked {
        background-color: rgb(var(--base));
        border: 1px solid rgb(var(--base)/.5);
    }
    &:focus {
        border: 1px solid rgb(var(--base)/.5);
        box-shadow: none;
    }
}