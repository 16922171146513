.newsletter {
  border: 1px solid hsl(var(--white));
  max-width: 280px;

  &-alt {
    border: 1px solid hsl(var(--white));
    max-width: 580px;
  }

  &__container {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  &__input {
    height: 38px;
    background: transparent;
    border: none;
    color: hsl(var(--white));
    font-size: 14px;

    &:focus {
      color: hsl(var(--white));
      outline: none;
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
      border-width: 0;
    }

    &::placeholder {
      color: hsl(var(--white));
    }
  }
}