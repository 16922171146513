.about-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 25px 15px;
    background: hsl(var(--canvas));
    @include md-screen {
        gap: 3.4rem;
        flex-direction: row;
        align-items: center;
        padding: 30px 40px;
        border-right: 2px solid rgb(var(--base));
    }
    &__img {
        width: 45px;
        height: 45px;
        display: grid;
        place-items: center;
        @include md-screen {
            position: relative;
            isolation: isolate;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: calc(100% + 1.57rem);
                transform: translateY(-50%);
                width: 1px;
                height: 20px;
                background: rgb(var(--base));
            }
        }
    }
    &__icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}