.upcoming-card {
    @include md-screen {
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }
    @include xlii-screen {
        gap: 2rem;
    }
    
    &__img {
        @include md-screen {
            width: 300px;
            height: 250px;
            flex-shrink: 0;
        }
        @include lg-screen {
            width: 200px;
            height: 200px;
        }
        @include xlii-screen {
            width: 300px;
            height: 250px;
        }
        &-is {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        color: hsl(var(--white));
        @include md-screen {
            text-align: start;
            margin-top: 0;
        }
    }
    &__content {
        flex-grow: 1;
        @include xlii-screen {
            padding-inline: 1rem;
        }
    }
}