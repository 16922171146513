.counter-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &__head {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    &__icon {
        width: 60px;
        height: 60px;
        display: grid;
        place-items: center;
        aspect-ratio: 1;
        border-radius: 50%;
        background: hsl(var(--accent-400));
        font-size: 28px;
        line-height: 1;
        color: hsl(var(--accent-600));
        flex-shrink: 0;
        @include xl-screen {
            width: 70px;
            height: 70px;
            font-size: 32px;
        }
    }
    &__title {
        font-family: var(--heading-font) !important;
        color: rgb(var(--base));
    }
}