.gallery-alt {
    position: relative;
    isolation: isolate;
    width: 100%;
    height: 300px;
    &:hover {
        .gallery-alt__overlay {
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }
    }
    @include xl-screen {
        height: 400px;
    }
    @include xlii-screen {
        height: 500px;
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__overlay {
        display: grid;
        place-items: center;
        position: absolute;
        inset: 0;
        background: hsl(var(--dark)/.7);
        font-size: 40px;
        line-height: 1;
        color: hsl(var(--white));
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        &:hover {
            color: hsl(var(--white));
        }
    }
}