.custom--modal {
    .modal-content {
      border-radius: 5px;
      background-color: hsl(var(--white));
      border: 1px solid hsl(var(--border));
    }
    .modal-title {
      margin-top: 0;
      color: hsl(var(--heading));
    }
    .btn-close {
      position: relative;
      background: transparent;
      color: hsl(var(--accent));
      opacity: 1;
      transition: all .3s ease;
      &:hover {
        outline: none;
        box-shadow: none;
        color: rgb(var(--base));
      }
      &::after {
        content: "\f00d";
        font-family: "Line Awesome Free";
        font-weight: 900;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 34px;
      }
    }
    .modal-body {
        p {
            margin-bottom: 0;
        }
    }
    .modal-header {
      border-bottom: 1px solid hsl(var(--border));
      background: hsl(var(--light));
    }
    .modal-footer {
      border-top: 1px solid hsl(var(--border));
      background: hsl(var(--light));
    }
  }
  
  .custom--modal-dark {
    .modal-content {
      border-radius: 5px;
      background: hsl(var(--accent-300));
      border: 1px solid hsl(var(--accent-50));
    }
    .modal-title {
      margin-top: 0;
      color: hsl(var(--white));
    }
    .btn-close {
      position: relative;
      background: transparent;
      color: hsl(var(--white));
      opacity: 1;
      transition: all .3s ease;
      &:hover {
        outline: none;
        box-shadow: none;
        color: rgb(var(--base));
      }
      &::after {
        content: "\f00d";
        font-family: "Line Awesome Free";
        font-weight: 900;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 34px;
      }
    }
    .modal-body {
        color: hsl(var(--white));
    }
    .modal-header {
      border-bottom: 1px solid hsl(var(--accent-50));
      background: hsl(var(--accent-300));
    }
    .modal-footer {
      border-top: 1px solid hsl(var(--accent-50));
      background: hsl(var(--accent-300));
    }
  }
  