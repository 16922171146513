.logo {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 140px;
  height: 46px;
  font-size: 24px;
  text-transform: capitalize;
  font-family: var(--heading-font);
  font-weight: 700;
  color: hsl(var(--white));
  letter-spacing: 0.03em;
  &:hover {
    color: hsl(var(--white));
  }
  @include lg-screen {
    height: 64px;
    max-width: 160px;
  }
  &__is {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
