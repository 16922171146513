.animal-category {
  &:hover {
    .animal-category__img-is {
      transform: scale(1.1);
    }
    .animal-category__img {
      border-image-source: linear-gradient(
        rgb(var(--base)) 50%,
        transparent 50%
      );
    }
  }
  &__img {
    display: inline-block;
    width: 140px;
    height: 140px;
    flex-shrink: 0;
    overflow: hidden;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 10px;
    border-image-source: linear-gradient(
      hsl(var(--white)) 50%,
      transparent 50%
    );
    @include md-screen {
      width: 125px;
      height: 125px;
    }
    @include lg-screen {
      width: 140px;
      height: 140px;
    }
    @include xl-screen {
      width: 170px;
      height: 170px;
    }
    @include xlii-screen {
      width: 180px;
      height: 180px;
    }

    &-is {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease;
    }
  }
  &__link {
    display: block;
    text-align: center;
    font-family: var(--heading-font);
    font-size: 14px;
    font-weight: 700;
    color: hsl(var(--heading));
    @include xl-screen {
      font-size: 16px;
    }
    &:hover {
      color: rgb(var(--base));
    }
  }
}
