.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 15px;
  width: 40px;
  height: 40px;
  background-color: rgb(var(--base));
  color: hsl(var(--white));
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  z-index: 99;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 0 5px hsl(var(--black)/.5);
  display: none;
}
