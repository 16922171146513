.custom--card {
    border-radius: 3px;
    background-color: hsl(var(--white));
    border: 1px solid hsl(var(--border));
    .card-header {
        padding: 1rem 1.5rem;
        background: hsl(var(--light));
        border-bottom: 1px solid hsl(var(--border));
    }
    .card-title {
        margin-top: 0;
        margin-bottom: 0;
    }
    .card-text {
        margin-top: 1rem;
    }
    .card-body {
        padding: 2rem 1.5rem;
    }
    .card-footer {
        padding: 1rem 1.5rem;
        background: hsl(var(--light));
        border-top: 1px solid hsl(var(--border));
    }
}
.custom--card-dark {
    border-radius: 3px;
    background: hsl(var(--accent-200));
    border: 1px solid hsl(var(--accent-300));
    .card-header {
        padding: 1rem 1.5rem;
        background: hsl(var(--accent)/.5);
        border-bottom: 1px solid hsl(var(--accent-300));
        color: hsl(var(--white));
    }
    .card-title {
        margin-top: 0;
        margin-bottom: 0;
        color: hsl(var(--white));
    }
    .card-text {
        margin-top: 1rem;
        color: hsl(var(--white));
    }
    .card-body {
        padding: 2rem 1.5rem;
    }
    .card-footer {
        padding: 1rem 1.5rem;
        background: hsl(var(--accent)/.5);
        border-top: 1px solid hsl(var(--accent-300));
    }
}