/* table css start */

.custom--table {
  margin-bottom: 0;
  box-shadow: 0 5px 15px rgb(var(--dark)/.05);
  background: hsl(var(--light));
  & > :not(:first-child) {
    border-top: 1px solid rgb(var(--base));
  }
  & > :not(caption) > * > * {
    border-bottom-width: 0;
  }
  thead {
    background-color: rgb(var(--base));

    th {
      padding: 0.75rem 1.25rem;
      border: 1px solid hsl(var(--border)/.5);
      font-family: var(--heading-font);
      color: hsl(var(--white));
      text-transform: uppercase;
      text-align: center;
      font-weight: 500;
      vertical-align: middle;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    td {
      border: 1px solid hsl(var(--border)/.5);
      padding: 15px 20px;
      text-align: center;
      vertical-align: middle;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }

    tr:last-child td {
      border-bottom: none;
    }
  }
  [data-label] {
    position: relative;
    &::before {
      position: absolute;
      content: attr(data-label);
      font-weight: 500;
      left: 0;
      padding: 0.8125rem 0.9375rem;
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .table-responsive--md {
    thead {
      display: none;
    }

    tbody tr {
      &:nth-child(odd) {
        background-color: hsl(var(--white));
      }

      &:last-child td {
        border-top: 1px solid rgb(var(--base)/.25);
        border-bottom: 1px solid rgb(var(--base)/.25);
      }

      td {
        padding-right: 15px;

        &:last-child {
          padding-right: 15px;
        }
      }
    }

    tr {
      th,
      td {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 45% !important;
        text-align: right !important;
      }

      th:first-child,
      td:first-child {
        border-top: none !important;
      }
    }

    [data-label]::before {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .table-responsive--sm {
    thead {
      display: none;
    }

    tbody tr {
      &:nth-child(odd) {
        background-color: hsl(var(--accent-400));
      }

      td {
        padding-right: 15px;

        &:last-child {
          padding-right: 15px;
        }
      }
    }

    tr {
      th,
      td {
        display: block;
        padding-left: 45% !important;
        text-align: right !important;
        border-bottom: 1px solid rgb(var(--base)/.25);
      }

      th:first-child,
      td:first-child {
        border-top: none !important;
      }
    }

    [data-label]::before {
      display: block;
    }
  }
}

/* table css end */
