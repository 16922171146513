.user {
    flex-shrink: 0;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    &--sm {
      width: 40px;
      height: 40px;
    }
    &--md {
      width: 50px;
      height: 50px;
    }
    &--lg {
      width: 60px;
      height: 60px;
    }
    &--xl {
      width: 70px;
      height: 70px;
    }
    &--xxl {
      width: 120px;
      height: 120px;
    }
    &__img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }