.hero-nav {
  &-container {
    padding-top: 15px;
    padding-bottom: 15px;
    @include md-screen {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
    @include lg-screen {
      left: auto;
      transform: translateX(0);
    }
    @include xliv-screen {
      right: 135px;
      transform: translateY(-50%) rotate(90deg);
      top: 50%;
    }
    @include xlv-screen {
      right: 175px;
    }
    @include xxl-screen {
      right: 300px;
    }
  }

  &__item {
    width: 60px !important;
    height: 60px;
    aspect-ratio: 1;
    flex-shrink: 0;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 7px;
    border-radius: 5px;
    background: hsl(var(--white));
    box-shadow: 0 0 10px hsl(var(--black) / 0.2);
    @include md-screen {
      width: 80px !important;
      height: 80px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
    @include xliv-screen {
        transform: rotate(-90deg);
    }
    &:hover {
        cursor: pointer;
    }
  }
  .slick-list {
    margin-top: -10px;
    margin-bottom: -10px;
    text-align: center;
    @include lg-screen {
      padding: 0 !important;
    }
  }
}
