.event-image-group {
  @include lg-screen {
    --gap: 2rem;
  }
  li {
    width: calc(50% - 1rem);
    @include md-screen {
      width: calc(33% - 1rem);
    }
    @include lg-screen {
      width: calc(33% - 2rem);
      &:nth-child(1) {
        margin-top: 30px;
      }
      &:nth-child(3) {
        margin-top: 30px;
      }
      &:nth-child(5) {
        margin-top: -30px;
      }
    }
  }
}
.event-image {
  width: 100%;
  aspect-ratio: 1;
  flex-shrink: 0;
}
