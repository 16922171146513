.icon {
    position: relative;
    display: grid;
    place-items: center;
    aspect-ratio: 1;
    // Shape 
    &--circle {
        border-radius: 50%;
        text-align: center;
    }
    &--sqr {
        border-radius: 5px;
        text-align: center;
    }
    &--eclipse {
        border-radius: 50% 0 50% 50%;
    }
    // Size 
    &--xxs {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
    }
    &--xs {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
    }
    &--sm {
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 20px;
    }
    &--md {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 22px;
    }
    &--lg {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 30px;
    }
    &--xl {
        width: 70px;
        height: 70px;
        line-height: 70px;
        font-size: 35px;
    }
    &--xxl {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 40px;
    }
    &--base {
        border: 1px solid rgb(var(--base));
        color: rgb(var(--base));
    }
}