.client-slider {
  &__btn {
    width: 45px;
    height: 45px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    outline: 5px solid rgb(var(--base)/.5);
    border-radius: 50%;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgb(var(--base));
    font-size: 20px;
    color: hsl(var(--white));
    text-align: center;
    transition: all 0.3s ease;
    z-index: 1;
    &:hover {
      background: hsl(var(--white));
      color: rgb(var(--base));
      outline: 5px solid rgb(var(--base));
    }
    &:focus {
      background: hsl(var(--white));
      color: rgb(var(--base));
      outline: 5px solid rgb(var(--base)) !important;
    }
    &-prev {
      left: 0;
      @include xl-screen {
          left: -25px;
      }
    }
    &-next {
      right: 0;
      @include xl-screen {
        right: -25px;
    }
    }
  }
}
