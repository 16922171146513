/*---------------------------------------
    2.1 Hero
-----------------------------------------*/
@import "hero";
/*---------------------------------------
    2.2 Header
-----------------------------------------*/
@import "header";
/*---------------------------------------
    2.3 Logo
-----------------------------------------*/
@import "logo";
/*---------------------------------------
    2.4 List
-----------------------------------------*/
@import "list";
/*---------------------------------------
    2.5 Nav
-----------------------------------------*/
@import "nav";
/*---------------------------------------
    2.6 Primary Menu
-----------------------------------------*/
@import "primary-menu";
/*---------------------------------------
    2.7 Nav Toggler
-----------------------------------------*/
@import "nav-toggler";

/*---------------------------------------
    2.8 Nice Select
-----------------------------------------*/
@import "nice-select";
/*---------------------------------------
    2.9 Form Control
-----------------------------------------*/
@import "form-control";
/*---------------------------------------
    2.10 Primary Submenu Toggler
-----------------------------------------*/
@import "primary-submenu-toggler";
/*---------------------------------------
    2.11 Preloader
-----------------------------------------*/
@import "preloader";
/*---------------------------------------
    2.12 Back To Top
-----------------------------------------*/
@import "back-top";
/*---------------------------------------
    2.13 Button Style
-----------------------------------------*/
@import "btn";
/*---------------------------------------
    2.14 Section 
-----------------------------------------*/
@import "section";
/*---------------------------------------
    2.15 Icon
-----------------------------------------*/
@import "icon";
/*---------------------------------------
    2.16 Search Popup
-----------------------------------------*/
@import "search-popup";
/*---------------------------------------
    2.17 Widget
-----------------------------------------*/
@import "widget";
/*---------------------------------------
    2.18 Widget Category
-----------------------------------------*/
@import "widget-category";
/*---------------------------------------
    2.19 User
-----------------------------------------*/
@import "user";
/*---------------------------------------
    2.20 Banner
-----------------------------------------*/
@import "banner";
/*---------------------------------------
    2.21 Table
-----------------------------------------*/
@import "table";
/*---------------------------------------
    2.22 Accordion
-----------------------------------------*/
@import "accordion";
/*---------------------------------------
    2.23 Badge
-----------------------------------------*/
@import "badge";
/*---------------------------------------
    2.24 Form Select
-----------------------------------------*/
@import "form-select";
/*---------------------------------------
    2.25 Custom Check
-----------------------------------------*/
@import "custom-check";
/*---------------------------------------
    2.26 Breadcrumbs
-----------------------------------------*/
@import "breadcrumbs";
/*---------------------------------------
    2.27 Custom Pagination
-----------------------------------------*/
@import "custom-pagination";
/*---------------------------------------
    2.28 Custom Card
-----------------------------------------*/
@import "custom-card";
/*---------------------------------------
    2.29 Custom Modal
-----------------------------------------*/
@import "custom-modal";
/*---------------------------------------
    2.30 Fixed Header 
-----------------------------------------*/
@import "fixed-header";
/*---------------------------------------
    2.31 User Group List
-----------------------------------------*/
@import "user-group-list";
/*---------------------------------------
    2.32 Newsletter
-----------------------------------------*/
@import "newsletter";
/*---------------------------------------
    2.33 Input Group
-----------------------------------------*/
@import "input-group";
/*---------------------------------------
    2.34 Hero Slider
-----------------------------------------*/
@import "hero-slider";
/*---------------------------------------
    2.35 Hero Nav
-----------------------------------------*/
@import "hero-nav";
/*---------------------------------------
    2.36 Animal List
-----------------------------------------*/
@import "animal-list";
/*---------------------------------------
    2.37 Welcom Card
-----------------------------------------*/
@import "welcome-card";
/*---------------------------------------
    2.38 Gallery Card
-----------------------------------------*/
@import "gallery-card";
/*---------------------------------------
    2.39 Counter Card
-----------------------------------------*/
@import "counter-card";
/*---------------------------------------
    2.40 Membership Card
-----------------------------------------*/
@import "membership-card";
/*---------------------------------------
    2.41 Feedback Card
-----------------------------------------*/
@import "feedback-card";
/*---------------------------------------
    2.42 Feedback Slider
-----------------------------------------*/
@import "feedback-slider";
/*---------------------------------------
    2.43 Event Card
-----------------------------------------*/
@import "event-card";
/*---------------------------------------
    2.44 Expert Card
-----------------------------------------*/
@import "expert-card";
/*---------------------------------------
    2.45 Blog Post
-----------------------------------------*/
@import "blog-post";
/*---------------------------------------
    2.46 Client Card
-----------------------------------------*/
@import "client-card";
/*---------------------------------------
    2.47 Subscribe Section
-----------------------------------------*/
@import "subscribe-section";
/*---------------------------------------
    2.48 Animal List Alternative Style
-----------------------------------------*/
@import "animal-list-alt";
/*---------------------------------------
    2.49 Animal Category
-----------------------------------------*/
@import "animal-category";
/*---------------------------------------
    2.50 Info Image
-----------------------------------------*/
@import "info-image";
/*---------------------------------------
    2.51 About Card
-----------------------------------------*/
@import "about-card";
/*---------------------------------------
    2.52 About Image
-----------------------------------------*/
@import "about-image";
/*---------------------------------------
    2.53 Counter List
-----------------------------------------*/
@import "counter-list";
/*---------------------------------------
    2.54 Gallery ALT Style
-----------------------------------------*/
@import "gallery-alt";
/*---------------------------------------
    2.55 Member Card
-----------------------------------------*/
@import "member-card";
/*---------------------------------------
    2.56 Testimonial Card
-----------------------------------------*/
@import "testimonial-card";
/*---------------------------------------
    2.57 Testimonial Slider
-----------------------------------------*/
@import "testimonial-slider";
/*---------------------------------------
    2.58 Team Card
-----------------------------------------*/
@import "team-card";
/*---------------------------------------
    2.59 Event Image
-----------------------------------------*/
@import "event-image";
/*---------------------------------------
    2.60 Upcoming Card
-----------------------------------------*/
@import "upcoming-card";
/*---------------------------------------
    2.61 Upcoming Slider
-----------------------------------------*/
@import "upcoming-slider";
/*---------------------------------------
    2.62 Animal Card
-----------------------------------------*/
@import "animal-card";
/*---------------------------------------
    2.63 Client Slider
-----------------------------------------*/
@import "client-slider";
/*---------------------------------------
    2.64 Animal Details Slider
-----------------------------------------*/
@import "animal-details-slider";
/*---------------------------------------
    2.65 Event Counter
-----------------------------------------*/
@import "event-counter";
/*---------------------------------------
    2.66 Fullsection Image
-----------------------------------------*/
@import "fullsection-img";
/*---------------------------------------
    2.67 Zoo Box
-----------------------------------------*/
@import "zoo-box";
/*---------------------------------------
    2.68 Service Card
-----------------------------------------*/
@import "service-card";
/*---------------------------------------
    2.69 Search Box
-----------------------------------------*/
@import "search-box";
/*---------------------------------------
    2.70 Classic Blog
-----------------------------------------*/
@import "classic-blog";
/*---------------------------------------
    2.71 Blockquote
-----------------------------------------*/
@import "blockquote";
/*---------------------------------------
    2.72 Divider
-----------------------------------------*/
@import "divider";
/*---------------------------------------
    2.73 Login Form
-----------------------------------------*/
@import "login-form";
/*---------------------------------------
    2.74 Map View
-----------------------------------------*/
@import "map-view";
/*---------------------------------------
    2.75 Header Top
-----------------------------------------*/
@import "header-top";

