.exhibition-section {
    position: relative;
    isolation: isolate;
    &::after {
        @include xxl-screen {
            content: url(../images/bear.png);
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -1;
          }
    }
}