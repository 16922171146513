.search-popup.active .search-form {
    visibility: visible;
    opacity: 1; 
    z-index: 9999;
}
.search--toggler {
  font-size: 23px;
  line-height: 1;
}
  .search-popup .search-form {
    min-width: 90%;
    @include md-screen {
        min-width: 50%;
    }
    @include xl-screen {
        min-width: 40%;
    }
    position: absolute;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    visibility: hidden;
    opacity: 0;
    -ms-transform: translate(-50%, -50%);
    /* IE 9 */
    -webkit-transform: translate(-50%, -50%);
    /* Chrome, Safari, Opera */
    transform: translate(-50%, -50%);
}
    .search-popup .search-form .form-group .form-control {
      border: none;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px; 
      height: 55px;
      padding: 0 100px 0 30px ;

    }
    .search-popup .search-form .submit-btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 55px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; 
      background-color: rgb(var(--base));
      transition: all .3s ease;
      padding: 0;
      text-align: center;
      border: 0;
      color: hsl(var(--white));
      &:hover{
        background: hsl(var(--accent));
        cursor: pointer;
      }
    }
  
  .body-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    display: block;
    background-color: hsl(var(--black)/.6);
    z-index: 9998;
    content: '';
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress; }
    .body-overlay.active {
      visibility: visible;
      opacity: .80; 
    }