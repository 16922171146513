.counter-list {
    --gap: 3rem;
    @include md-screen {
        flex-direction: row;
        flex-wrap: wrap;
        --gap: 2rem;
    }
    @include lg-screen {
        padding-top: 30px;
        padding-bottom: 30px;
        border: 1px solid hsl(var(--border)/.7);
    }
    li {
        @include md-screen {
            width: calc(50% - 2rem);
        }
        @include lg-screen {
            width: calc(25% - 2rem);
            position: relative;
            isolation: isolate;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: calc(100% + 1rem);
                transform: translateY(-50%);
                width: 1px;
                height: 120px;
                background: hsl(var(--border) /.7);
            }
        }
    }
    &__icon {
        width: 50px;
        height: 50px;
        position: relative;
        isolation: isolate;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 50px;
            height: 4px;
            background: rgb(var(--base));
        }
    }
    &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1rem;
        @include md-screen {
            aspect-ratio: 1 /.7;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .odometer {
        font-family: var(--heading-font);
    }
}