.pagination {
  margin-bottom: 0;
  .page-item {
    margin-right: 1rem;
    &.active {
      .page-link {
        background-color: rgb(var(--base));
        border: 1px solid rgb(var(--base));
      }
    }
    &.disabled {
      .page-link {
        background: hsl(var(--accent-300));
        border-color: hsl(var(--accent-300));
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      .page-link {
        border-radius: 50%;
        font-size: 18px;
        line-height: 1;
      }
    }
    &:last-child {
      .page-link {
        border-radius: 50%;
        font-size: 18px;
        line-height: 1;
      }
    }
  }
  .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgb(var(--base));
    color: rgb(var(--base));
    text-align: center;
    font-size: 14px;
    background: transparent;
    &:hover {
      background-color: rgb(var(--base));
      border: 1px solid rgb(var(--base));
      color: hsl(var(--white));
    }
  }
}
