// Margin Top
.t-mt-5 {
  margin-top: 5px;
}
.t-mt-10 {
  margin-top: 10px;
}
.t-mt-15 {
  margin-top: 15px;
}
.t-mt-20 {
  margin-top: 20px;
}
.t-mt-25 {
  margin-top: 25px;
}
.t-mt-30 {
  margin-top: 30px;
}
.t-mt-35 {
  margin-top: 35px;
}
.t-mt-40 {
  margin-top: 40px;
}
.t-mt-45 {
  margin-top: 45px;
}
.t-mt-50 {
  margin-top: 50px;
}
.t-mt-55 {
  margin-top: 55px;
}
.t-mt-60 {
  margin-top: 60px;
}
.t-mt-65 {
  margin-top: 65px;
}
.t-mt-70 {
  margin-top: 70px;
}
.t-mt-75 {
  margin-top: 75px;
}
.t-mt-80 {
  margin-top: 80px;
}
.t-mt-85 {
  margin-top: 85px;
}
.t-mt-90 {
  margin-top: 90px;
}
.t-mt-95 {
  margin-top: 95px;
}
.t-mt-100 {
  margin-top: 100px;
}
.t-mt-105 {
  margin-top: 105px;
}
.t-mt-110 {
  margin-top: 110px;
}
.t-mt-115 {
  margin-top: 115px;
}
.t-mt-120 {
  margin-top: 120px;
}

// Margin Bottom
.t-mb-5 {
  margin-bottom: 5px;
}
.t-mb-10 {
  margin-bottom: 10px;
}
.t-mb-15 {
  margin-bottom: 15px;
}
.t-mb-20 {
  margin-bottom: 20px;
}
.t-mb-25 {
  margin-bottom: 25px;
}
.t-mb-30 {
  margin-bottom: 30px;
}
.t-mb-35 {
  margin-bottom: 35px;
}
.t-mb-40 {
  margin-bottom: 40px;
}
.t-mb-45 {
  margin-bottom: 45px;
}
.t-mb-50 {
  margin-bottom: 50px;
}
.t-mb-55 {
  margin-bottom: 55px;
}
.t-mb-60 {
  margin-bottom: 60px;
}
.t-mb-65 {
  margin-bottom: 65px;
}
.t-mb-70 {
  margin-bottom: 70px;
}
.t-mb-75 {
  margin-bottom: 75px;
}
.t-mb-80 {
  margin-bottom: 80px;
}
.t-mb-85 {
  margin-bottom: 85px;
}
.t-mb-90 {
  margin-bottom: 90px;
}
.t-mb-95 {
  margin-bottom: 95px;
}
.t-mb-100 {
  margin-bottom: 100px;
}
.t-mb-105 {
  margin-bottom: 105px;
}
.t-mb-110 {
  margin-bottom: 110px;
}
.t-mb-115 {
  margin-bottom: 115px;
}
.t-mb-120 {
  margin-bottom: 120px;
}

// Margin Right
.t-mr-5 {
  margin-right: 5px;
}
.t-mr-10 {
  margin-right: 10px;
}
.t-mr-15 {
  margin-right: 15px;
}
.t-mr-20 {
  margin-right: 20px;
}
.t-mr-25 {
  margin-right: 25px;
}
.t-mr-30 {
  margin-right: 30px;
}
.t-mr-35 {
  margin-right: 35px;
}
.t-mr-40 {
  margin-right: 40px;
}
.t-mr-45 {
  margin-right: 45px;
}
.t-mr-50 {
  margin-right: 50px;
}
.t-mr-55 {
  margin-right: 55px;
}
.t-mr-60 {
  margin-right: 60px;
}
.t-mr-65 {
  margin-right: 65px;
}
.t-mr-70 {
  margin-right: 70px;
}
.t-mr-75 {
  margin-right: 75px;
}
.t-mr-80 {
  margin-right: 80px;
}
.t-mr-85 {
  margin-right: 85px;
}
.t-mr-90 {
  margin-right: 90px;
}
.t-mr-95 {
  margin-right: 95px;
}
.t-mr-100 {
  margin-right: 100px;
}
.t-mr-105 {
  margin-right: 105px;
}
.t-mr-110 {
  margin-right: 110px;
}
.t-mr-115 {
  margin-right: 115px;
}
.t-mr-120 {
  margin-right: 120px;
}

// Margin Left
.t-ml-5 {
  margin-left: 5px;
}
.t-ml-10 {
  margin-left: 10px;
}
.t-ml-15 {
  margin-left: 15px;
}
.t-ml-20 {
  margin-left: 20px;
}
.t-ml-25 {
  margin-left: 25px;
}
.t-ml-30 {
  margin-left: 30px;
}
.t-ml-35 {
  margin-left: 35px;
}
.t-ml-40 {
  margin-left: 40px;
}
.t-ml-45 {
  margin-left: 45px;
}
.t-ml-50 {
  margin-left: 50px;
}
.t-ml-55 {
  margin-left: 55px;
}
.t-ml-60 {
  margin-left: 60px;
}
.t-ml-65 {
  margin-left: 65px;
}
.t-ml-70 {
  margin-left: 70px;
}
.t-ml-75 {
  margin-left: 75px;
}
.t-ml-80 {
  margin-left: 80px;
}
.t-ml-85 {
  margin-left: 85px;
}
.t-ml-90 {
  margin-left: 90px;
}
.t-ml-95 {
  margin-left: 95px;
}
.t-ml-100 {
  margin-left: 100px;
}
.t-ml-105 {
  margin-left: 105px;
}
.t-ml-110 {
  margin-left: 110px;
}
.t-ml-115 {
  margin-left: 115px;
}
.t-ml-120 {
  margin-left: 120px;
}
